// main is an optional flag to include a path in main navbar 

export const paths = {
    intercoms: {
        title: 'Intercoms',
        main: true
    },
    videoconcierge: {
        title: 'Video Concierge',
        main: true
    },
    haas: {
        title:  'Help As A Service',
        main: true,
        style: {fontWeight: 'bolder'}
    },
    contact: {
        title: 'Contact Us',
        href: '#contact',
        main: true
    },
    home: {
        title: 'Home'
    },
    technologies: {
        title: 'Technologies'
    },
    awards: {
        title: 'Awards'
    },
    about: {
        title: 'About us'
    },
    qrvideo: {
        title: 'QR Video Intercom'
    },
    outdoor: {
        title: 'Outdoor Display'
    },
    cases: {
        title: 'Case Studies'
    },
    facilities: {
        title: 'Facilities'
    },
    hospitals: {
        title: 'Hospitals'
    },
    transport: {
        title: 'Transport'
    },
    highschool: {
        title: 'Higher Ed.'
    }
}

export const fullPathOrder = [
        [
            'home',
            'technologies',
            'awards',
            'about'
        ],
        [
            'intercoms',
            'qrvideo',
            'outdoor'
        ],
        [
            'videoconcierge',
            'haas',
        ],
        [
            'cases',
            'facilities',
            'hospitals',
            'transport',
            'highschool'
        ]
    
]

export const purchasePaths = {
    signup: {
        us: 'https://us.portal.teleportivity.com/register',
        uk: 'https://uk.portal.teleportivity.com/register',
        au: 'https://portal.teleportivity.com/register',
    },
    login: {
        us: 'https://us.portal.teleportivity.com/login',
        uk: 'https://uk.portal.teleportivity.com/login',
        au: 'https://portal.teleportivity.com/login',     
    },
    trial: '/',
    buynow: '/'
}

export const qrideoPaths = {
    contact: 'https://qrideo.me/contact/us',
    commercial: 'https://qrideo.me/contact/commercial',
    warehouse: 'https://qrideo.me/contact/warehouse',
    terms: 'https://portal.teleportivity.com/terms-and-conditions.html',
    privacy: 'https://portal.teleportivity.com/privacy-terms'
}

export const downloadLinks = {
    appleStore: 'https://apps.apple.com/us/app/teleportivity-me/id1440282196',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.teleportivity.android_operator',
    windows10: 'https://electron-autoupdate-bucket.s3.amazonaws.com/prod/teleportivity-live-xl.exe',
    linuxArm: 'https://electron-autoupdate-bucket.s3.amazonaws.com/prod/teleportivity-live-xl-arm64.AppImage',
    linuxX86: 'https://electron-autoupdate-bucket.s3.amazonaws.com/prod/teleportivity-live-xl-x86_64.AppImage',
}
