import React from "react"
import "./Downloads.sass";
import MobileAppsBlock from "./MobileAppsBlock";
import WelcomeBlock from "./WelcomeBlock";
import FonecomeBlock from "./FonecomeBlock";

const DownloadsPage = (props) => {
    return <>
        <WelcomeBlock />
        <MobileAppsBlock />
        <FonecomeBlock />
    </>
}

export default DownloadsPage;
