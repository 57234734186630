import React from "react"
import QRCodeLink from "../../components/QRcodeLink";
import Image from "../../components/Image";
import MulticardScreen from "../../components/ContentScreens/MulticardScreen";
import { downloadLinks, qrideoPaths } from "../../const/paths";
import { useMobileDetect } from "react-mobile-detect";

const MobileAppsBlock = () => {
    const { isMobile } = useMobileDetect();

    if (isMobile) {
        return (
            <MulticardScreen
                cards={[
                    {
                        bgColor: 'none',
                        title: <div>Answer Intercom calls on your mobile device</div>,
                        subtitle: 'Please check your device compatibility before downloading and installing the selected application',
                        text: <div>
                            <span>
                                Click <a href={qrideoPaths.contact} target="_blank">here</a> for our ‘quick start’ phone guide
                            </span>
                        </div>,
                        className: 'homepage-splash'
                    },
                    {
                        className: 'no-bottom-corners download-mobile-card',
                        subtitle: 'iPhone',
                        text: <div style={{ display: 'flex', gap: '56px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <QRCodeLink href={downloadLinks.appleStore} />
                            <img className="mobile-store-icon" src="/img/app-store.png" />
                        </div>,
                        attachment: <Image className='mobile-store-device' src={'/img/iphons.png'} />
                    },
                    {
                        className: 'no-bottom-corners download-mobile-card',
                        subtitle: 'Android',
                        text: <div style={{ display: 'flex', gap: '56px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <QRCodeLink href={downloadLinks.googlePlay} />
                            <img className="mobile-store-icon" src="/img/google-play.png" />
                        </div>,
                        attachment: <Image className='mobile-store-device' src={'/img/androids.png'} />
                    },
                ]}
            />
        )
    }
    return (
        <MulticardScreen
            subtitle={'Answer Intercom calls on your mobile device'}
            text={
                <div>
                    <span>
                        Click <a href={qrideoPaths.contact} target="_blank">here</a> for our ‘quick start’ phone guide
                    </span>
                </div>
            }
            cards={[
                {
                    className: 'no-bottom-corners download-mobile-card',
                    subtitle: 'iPhone',
                    text: <div style={{ display: 'flex', gap: '56px', justifyContent: 'space-between', alignItems: 'center' }}>
                        <QRCodeLink href={downloadLinks.appleStore} />
                        <img className="mobile-store-icon" src="/img/app-store.png" />
                    </div>,
                    attachment: <Image className='mobile-store-device' src={'/img/iphons.png'} />
                },
                {
                    className: 'no-bottom-corners download-mobile-card',
                    subtitle: 'Android',
                    text: <div style={{ display: 'flex', gap: '56px', justifyContent: 'space-between', alignItems: 'center' }}>
                        <QRCodeLink href={downloadLinks.googlePlay} />
                        <img className="mobile-store-icon" src="/img/google-play.png" />
                    </div>,
                    attachment: <Image className='mobile-store-device' src={'/img/androids.png'} />
                },
            ]}
        />
    )
}

export default MobileAppsBlock;
