import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {paths, qrideoPaths} from '../../const/paths';
import Button from '../Button';
import QRCodeLink from '../QRcodeLink';
import './MultiColumnNavbar.sass'

// links are added thru a two-level array:
// [ column: [row1, row2, row3..]] 
// each entry is either a string (points to an entry on paths const), or an object {path: string, title: title}

const populateColumn = (column) => {
    return column
        .map((item) => {
        if (typeof item === 'string') return {...paths[item], path: item}
        return item
        })
        .filter((o) => Boolean(o))
}

const LinkColumn = (props) => {
    const { column, columnClassName } = props;

    return <div className={classNames('multicolumn-navbar-column', columnClassName)}>
    {
        populateColumn(column).map((item) => <a href={item.path} key={item.path}>
            <span style={item.style}>{item.title}</span>
        </a>)
    }
    </div>
}

const MultiColumnNavbar = (props) => {
    const {
        className,
        columnClassName,
        links
    } = props;

    return <div className={classNames('multicolumn-navbar', className)}>
        {
            links.map((column) => 
                <LinkColumn
                    className={columnClassName}
                    key={`${column[0]}_col`}
                    column={column}
                />)
        }
        <div>
            <Button color="none" href={'/downloads'} title={<a style={{color: 'white', gap: '10px', alignItems: 'center', display: 'flex'}}>
                <img src="img/download-icon.png" />
                Download
            </a>}/>
            <div className='buttonblock'>
                <Button
                    image={<QRCodeLink className={'test'} href={'http://qrideo.me/contact/us'}/>}
                    color={'gray'}
                    className={'component-contact-section-qrcode'}
                    titleClassName={'component-contact-section-qrcode-title'}
                    title={<>
                        SCAN to talk to us live!
                        Or click <a href={qrideoPaths.contact} target="_blank">here</a>
                    </>}
                />
                <span><a href={qrideoPaths.contact}>{qrideoPaths.contact}</a></span>
                <span><a href="mailto:contactus@teleportivity.com">contactus@teleportivity.com</a></span>
            </div>
        </div>
        
    </div>
}

export default MultiColumnNavbar