import React from "react"
import MulticardScreen, { vOrders } from "../../components/ContentScreens/MulticardScreen";
import "./Downloads.sass";
import { useMobileDetect } from "react-mobile-detect";
import classNames from "classnames";

const WelcomeBlock = () => {
    const { isMobile } = useMobileDetect()
    return (
        <MulticardScreen
            className={classNames('homesplash', {['welcome-block']: isMobile})}
            screenBackground={isMobile && '/img/rectangle-471.png'}
            cards={[
                {
                    bgColor: 'none',
                    title: <div>Downloads<br />& Apps</div>,
                    subtitle: 'Please check your device compatibility before downloading and installing the selected application',
                    className: 'homepage-splash'
                },
                {
                    background: '/img/macbook-air-1.png',
                    bgColor: 'none',
                    className: 'container-macbook-image',
                    text: isMobile ? null : <div className="rectangle-ball" />
                }
            ]}
            vOrder={vOrders.reversed}
        />
    )
}

export default WelcomeBlock;
