import React from "react"
import Image from "../../components/Image";
import Button from "../../components/Button";
import MulticardScreen from "../../components/ContentScreens/MulticardScreen";
import "./Downloads.sass";
import { useMobileDetect } from "react-mobile-detect";
import { downloadLinks } from "../../const/paths";

const FonecomeBlock = () => {
    const { isMobile } = useMobileDetect();
    if (isMobile) {
        return (
            <MulticardScreen
                cards={
                    [
                        {
                            bgColor: 'none',
                            title: 'Installing a Fonecom or Video Concierge display?',
                            text: 'Choose your device and download',
                        },
                        {
                            title: 'Windows PC',
                            text: 'Windows v10',
                            className: 'download-platforms-cards homepage-splash',
                            attachment: <>
                                <Image src='img/monitor.png' />
                                <div>
                                    <Button image={'img/download-icon.png'} href={downloadLinks.windows10} title="Download" color='blue' />
                                </div>
                            </>,
                        },
                        {
                            title: 'Fonecom 7',
                            className: 'download-platforms-cards homepage-splash',
                            text: 'Linux',
                            attachment: <>
                                <Image src='img/linux-fonecom.png' />
                                <Button image={'img/download-icon.png'} href={downloadLinks.linuxArm} title="Download" color='blue' />
                            </>
                        },
                        {
                            title: 'Fonecom 10',
                            className: 'download-platforms-cards homepage-splash',
                            text: 'Linux x86',
                            attachment: <>
                                <Image src='img/linux-fonecom-x86.png' />
                                <Button image={'img/download-icon.png'} href={downloadLinks.linuxX86} title="Download" color='blue' />
                            </>,
                        }
                    ]
                }
            />
        )
    }
    return (
        <MulticardScreen
            subtitle='Installing a Fonecom or Video Concierge display?'
            text={<div>
                Choose your device and download
            </div>}
            cards={
                [
                    {
                        title: 'Windows PC',
                        text: 'Windows v10',
                        className: 'download-platforms-cards',
                        attachment: <>
                            <Image src='img/monitor.png' />
                            <div>
                                <Button image={'img/download-icon.png'} href={downloadLinks.windows10} title="Download" color='blue' />
                            </div>

                        </>,
                    },
                    {
                        title: 'Fonecom 7',
                        className: 'download-platforms-cards',
                        text: 'Linux',
                        attachment: <>
                            <Image src='img/linux-fonecom.png' />
                            <Button image={'img/download-icon.png'} href={downloadLinks.linuxArm} title="Download" color='blue' />
                        </>
                    },
                    {
                        title: 'Fonecom 10',
                        className: 'download-platforms-cards',
                        text: 'Linux x86',
                        attachment: <>
                            <Image src='img/linux-fonecom-x86.png' />
                            <Button image={'img/download-icon.png'} href={downloadLinks.linuxX86} title="Download" color='blue' />
                        </>,
                    }
                ]
            }
        />
    )
}

export default FonecomeBlock;
